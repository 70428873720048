import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {library} from "@fortawesome/fontawesome-svg-core"
import {fas} from "@fortawesome/free-solid-svg-icons"
import 'animate.css'
import globals from './utilities/utils'
import {createPinia} from 'pinia'
import 'jquery-ui'
import 'jquery-ui-css'
import "./assets/dash/dist/css/googlefonts.css"
import './assets/dash/plugins/fontawesome-free/css/all.css'
import './assets/cdn/css/overlayScrollBars.css'
import "./assets/dash/dist/css/adminlte.css"
import Select2 from 'vue3-select2-component'

library.add(fas)
const app = createApp({
    render: ()=>h(App)
})
const pinia = createPinia()
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('vue-select2', Select2)
app.use(pinia)
app.use(router)
for (const [key, value] of Object.entries(globals)) {
    app.provide(`${key}`, value)
}

app.mount('#app')
