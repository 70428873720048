<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "App",
  components: {}
}
</script>

<style>
input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}
</style>