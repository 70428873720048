import Swal from "sweetalert2"
import $ from 'jquery'

const animacionEntrada = {
    popup: 'animate__animated animate__heartBeat'
}

const animacionSalida = {
    popup: 'animate__animated animate__flipOutX'
}

const extend = function () {
	// Variables
	let extended = {}
	let deep = false
	let i = 0
	let length = arguments.length

	// Check if a deep merge
	if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
		deep = arguments[0]
		i++
	}

	// Merge the object into the extended object
	const merge = function (obj) {
		for ( let prop in obj ) {
			if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
				// If deep merge and property is an object, merge properties
				if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
					extended[prop] = extend( true, extended[prop], obj[prop] )
				} else {
					extended[prop] = obj[prop]
				}
			}
		}
	}

	// Loop through each object and conduct a merge
	for ( ; i < length; i++ ) {
		var obj = arguments[i]
		merge(obj)
	}
	return extended
}

const Swalert = (
    texto, tipoSwal = 'success', funcion = function () { }, warningParams = {
        totalBotones: 1,
        mostrarBotonCancelar: false,
        textoBtnConfirm: 'Sí, aceptar',
        textoBtnCancelar: 'No, cancelar',
        backdrop: true
    }, withTimer = false, timer = 1000
) => {
    var propsWarning = {
        showCancelButton: false,
        backdrop: true,
    }
    if (tipoSwal === 'warning' && warningParams.totalBotones === 2) {
        propsWarning.confirmButtonText = warningParams.textoBtnConfirm
        propsWarning.cancelButtonText = warningParams.textoBtnCancelar
        propsWarning.showCancelButton = warningParams.mostrarBotonCancelar
    }
    let tipoSwAl = ""
    let tituloSwal = ""
    switch (tipoSwal) {
        case 'success':
            tipoSwAl = tipoSwal
            tituloSwal = "Realizado!"
            break;
        case 'error':
            tipoSwAl = tipoSwal;
            tituloSwal = "Error!"
            break;
        case 'warning':
            tipoSwAl = tipoSwal;
            tituloSwal = "Atención!"
            break;
        default: tipoSwAl = 'error'
            tituloSwal = 'Error!'
            break;
    }
    let swalOptions = {
        title: tituloSwal,
        html: texto,
        icon: tipoSwAl,
        showClass: animacionEntrada,
        hideClass: animacionSalida,
        allowOutsideClick: false,
        confirmButtonColor: '#0069D9',
    }
    if (withTimer) {
        swalOptions.timer = timer;
        swalOptions.timerProgressBar = true;
    }
    let mamaste = extend(swalOptions, propsWarning);
    console.log('mamastes', mamaste);
    let opciones = $.extend({}, swalOptions, propsWarning);
    console.log('opciones', opciones);
    Swal.fire(opciones).then((result) => {
        if (result.isConfirmed) {
            funcion()
        }
    })
}

const transformToSelect2 = (object, idElement = 'id', texto = 'text') => {
    let array = []
    if(object !== null || object !== undefined){
        if(Object.keys(object).length > 0){
            for(let objeto of object){
                let item = {id: '', text: ''}
                if(idElement in objeto){
                    item.id = `${objeto[idElement]}`
                }
                if(texto in objeto){
                    item.text = objeto[texto]
                }
                array.push(item);
            }
        }
    }
    
    return array
}

export default {
    Swalert,
    transformToSelect2
}