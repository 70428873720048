import {useAuthStore} from '@/store/authStore'

const isLoggedInGuard = async( to, from, next ) => {
    const {ok} = await useAuthStore().checkAuthentication()
    if ( !ok ){
        next()
    }
    else{
        next({ name: from.name })
    }
}

export default isLoggedInGuard