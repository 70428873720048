import axios from "axios"

const seNicodemusApi = axios.create({
    // withCredentials: true,
    baseURL: process.env.VUE_APP_API,
    // headers: { "Access-Control-Allow-Origin": "http://localhost:8080" }
})

axios.defaults.withCredentials = true
seNicodemusApi.interceptors.request.use( (config) => {

    const accessToken = localStorage.getItem('jwt')
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers.Accept = 'application/json',
    config.headers["Content-Type: application/json"]

    return config
})

export default seNicodemusApi