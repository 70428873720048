import { defineStore } from 'pinia'
import seNicodemusApi from '@/api/seNicodemusApi'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        status: 'authenticating',
        jwt: ''
    }),
    actions: {
        async loginUser(usuario, password){
            try{
                const datos = {
                    usuario,
                    password
                }
                const {data} = await seNicodemusApi.post('/auth/login', datos)
                localStorage.setItem('jwt', data.access_token)
                this.status = 'authenticated'
                return{ ok: true, message: ''}
            }
            catch(error){
                this.status = 'not-authenticated'
                const codigo = error.response.status
                const msjeSwal = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña`: 
                `Ocurrió un error interno en el servidor, intenta más tarde`
                return {ok: false, message: msjeSwal}
            }
        },
        async checkAuthentication(){
            try{
                const {data} = await seNicodemusApi.get('usuarios/validateToken')
                console.log('PAYLOAD', data)
                const {usuario, id_usuario} = data
                const userData = {
                    usuario,
                    id_usuario
                }
                localStorage.setItem('payload', JSON.stringify(userData))
                this.status = 'authenticated'
                return {ok: true}
            }
            catch(error){
                return {ok: false, message: error.response}
            }
        },
        logout(){
            this.status = 'not-authenticated'
            this.jwt = ''
            localStorage.removeItem('jwt')
            localStorage.removeItem('payload')
        }
    }
})