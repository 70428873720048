import {useAuthStore} from '@/store/authStore'

const isAuthenticatedGuard = async( to, from, next ) => {
    const {ok} = await useAuthStore().checkAuthentication()
    if ( ok ){
        next()
    }
    else{
        next({ name: 'login' })
    }
}

export default isAuthenticatedGuard