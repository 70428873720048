import { createRouter, createWebHistory } from 'vue-router'
import isAuthenticatedGuard from '@/router/authGuard'
import isLoggedInGuard from '@/modules/login/router/isLoggedInGuard'

const routes = [
    {
        path: "/",
        name: "",
        beforeEnter: [ isAuthenticatedGuard ],
        redirect: 'home',
        component: () => import('../components/DashboardComponent'),
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "HomeView" */ '@/modules/home/components/HomeComponent')
            },
            {
                path: '/admin-empresas',
                name: 'admin-empresas',
                component: () => import('@/modules/administracion/empresas/components/EmpresasHomeComponent')
            },
            {
                path: '/admin-usuarios',
                name: 'admin-usuario',
                component: () => import('@/modules/administracion/usuarios/components/AdminUsuariosComponent')
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: [ isLoggedInGuard ],
        component: () => import(/* webpackChunkName: "LoginView" */ '@/modules/login/views/LoginView')
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('../components/NotFoundPage')
    }
]



const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router